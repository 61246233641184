import { KagamiBeanUtils } from './KagamiBeanUtils';
import {
  extractDropdownOption,
  getAttributeName,
  getEntityConsumedInAttribute,
  getHtmlControl
} from '../builder/home/builder/body/builder/process/service/PresentationService';
import { SystemConstants } from '../constants/SystemConstants';
import { getDateStringAsLocalString, isTypeDate } from './DateUtils';
import excel from '../../assets/images/filetypes/excel.svg';
import image from '../../assets/images/filetypes/image.png';
import defaultIcon from '../../assets/images/filetypes/defaultIcon.png';
import pdf from '../../assets/images/filetypes/pdf.svg';
import powerpoint from '../../assets/images/filetypes/powerpoint.svg';
import text from '../../assets/images/filetypes/text.svg';
import word from "../../assets/images/filetypes/word.svg"
import { getDmsViewUrl, getDownloadFileUrl } from '../service/URLService';
import { KagamiDMSModel } from '../builder/home/builder/body/builder/process/builder/dms/model/kagamiDMSModel';
import { buildDms } from '../builder/home/builder/body/builder/process/builder/dms/controller/kagamiDMSController';
import { buildDownloadButton } from '../builder/home/builder/body/builder/process/builder/controls/file/ui/KagamiFileField';
import { buildtoastNotification } from '../builder/home/builder/ToastNotification/controller/ToastNotificationController';
export class CommonUtils {
  static getDisplayValueForAttribute(dataRecord: any, attributePresentation: any) {
    let toReturn;
    var dataValue = KagamiBeanUtils.getProperty(getAttributeName(attributePresentation), dataRecord);
    if (
      getHtmlControl(attributePresentation) === 'select' ||
      getHtmlControl(attributePresentation) === 'search' ||
      getHtmlControl(attributePresentation) === 'radio'
    ) {
      var associatedValue = KagamiBeanUtils.getProperty(
        getEntityConsumedInAttribute(attributePresentation),
        dataRecord
      );
      var option = extractDropdownOption(attributePresentation, associatedValue);
      toReturn = option != null ? option['value'].toString() : dataValue ?? SystemConstants.DOUBLE_DASH;
    } else {
      toReturn = CommonUtils.getDisplayValueForProperty(
        getAttributeName(attributePresentation),
        getHtmlControl(attributePresentation),
        dataRecord
      );
    }
    return toReturn ? toReturn : SystemConstants.NA;
  }
  static getDisplayValueForProperty(property: string, type: string, record: any) {
    var dataValue = KagamiBeanUtils.getProperty(property, record);
    return CommonUtils.getDisplayValue(dataValue, type);
  }

  static getDisplayValue(dataValue: any, type: any) {
    let toReturn;
    if (dataValue == null) {
      return null;
    } else if (isTypeDate(type)) {
      toReturn = getDateStringAsLocalString(dataValue, type);
    } else if (type === 'text' || type === 'number' || 'longtext') {
      toReturn = dataValue;
    }
    // } else if (dataValue is double) {
    //   toReturn = _getNumberValueToDisplay(dataValue);
    // } else if (dataValue is bool) {
    //   toReturn = dataValue.toString();
    // }
    return toReturn;
  }
  static isEmpty(value: any) {
    if (
      value === "removed_" ||
      value === null ||
      value === undefined ||
      value === '' ||
      (value instanceof Object && Object.keys(value).length == 0) ||
      (typeof value === 'number' && (value === null || value === undefined)) ||
      (value instanceof String && value === SystemConstants.EMPTY) ||
      (value instanceof Array && value.length == 0) ||
      (value instanceof Map && value.size == 0 && Object.keys(value).length === 0)
    ) {
      return true;
    }
    return false;
  }
  static isRemoved(value: any) {
    return value === SystemConstants.REMOVED_;
  }
  static isNotEmpty(value: any) {
    return !CommonUtils.isEmpty(value);
  }
  static clone(data: any) {
    let toReturn: any;
    if (data instanceof Map) {
      let n: any = new Map();
      for (let [property, value] of Object.entries(data)) {
        value instanceof Array || value instanceof Map ? (n[property] = this.clone(value)) : (n[property] = value);
      }
      toReturn = n as any;
    } else if (data instanceof Array) {
      let n: Array<any> = new Array();
      for (let i = 0; i < data.length; i++) {
        var obj = data[i];
        obj instanceof Map || obj instanceof Array ? n.push(this.clone(obj)) : n.push(obj);
      }
      toReturn = n as any;
    }
    return toReturn;
  }
  static getProperty(nestedProperty: string, object: any) {
    if (this.isEmpty(nestedProperty)) {
      return null;
    }
    let properties: Array<any> = nestedProperty.split('.');
    for (var property in properties) {
      if (object == null || object == undefined || (object['entityType'] && object['entityType'] == property)) {
        return null;
      }
    }
  }
  static getSimpleProperty(property: string, object: any) {
    if (this.isNotEmpty(property) && object) {
      // if(object instanceof Map){
      //   let toReturn = object[property];
      //   if()
      // }
    }
  }

  static _getVariableValue(variable: any, data: any) {
    if (variable == null) return null;
    if (!variable) return '';
    // if (variable.startsWith('UserContext')) {
    //   return PolicyExecutionService._getContextVariable(
    //     'UserContext',
    //     KagamiApplicationController.applicationModel.userContext,
    //     variable
    //   );
    // } else if (variable.startsWith('ApplicationContext')) {
    //   return PolicyExecutionService._getContextVariable(
    //     'ApplicationContext',
    //     KagamiApplicationController.applicationModel.appContext,
    //     variable
    //   );
    // }
    let _attributes = variable.split('.');
    if (_attributes.length == 2) {
      return data != null ? (data[_attributes[1]] ? data[_attributes[1]] : null) : null;
    }
    var _local = data != null ? data[_attributes[1]] : null;
    _attributes.reverse();
    _attributes.pop(0);
    _attributes.reverse();
    if (_local instanceof Array) {
      var _toReturn: any[] = [];
      for (var i = 0; i < _local.length; i++) {
        var _result = CommonUtils._getVariableValue(_attributes.join('.'), _local[i]);
        _result instanceof Array ? _toReturn.push(_result) : _toReturn.push(_result);
      }
      return _toReturn;
    }
    _toReturn = CommonUtils._getVariableValue(_attributes.join('.'), _local);
    return _toReturn;
  }
  static _debounceFtn = (func: any, wait: number) => {
    let timeout: any;
    return (...args: any[]) => {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  static isArray(value: any) {
    if (value instanceof Array) {
      return true;
    } else {
      return false;
    }
  }
  static _needWindowReload(time: number) {
    return setTimeout(() => {
      window.location.reload();
    }, time);
    
  }
  static descendingComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  static getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => this.descendingComparator(a, b, orderBy)
      : (a: any, b: any) => this.descendingComparator(a, b, orderBy);
  }



  static stableSort(array: any, comparator: any) {
    const stabilizedThis = array.map((el: any, index: number) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el: any) => el[0]);
  }
  static getFileTypeICon = (type: any) => {
    switch (type) {
      case 'png':
      case 'jpeg':
      case 'jpg':
        return image;
      case 'pdf':
      case 'pd':
        return pdf;
      case 'xlsx':
      case 'xlsm':
      case 'xlsb':
      case 'xltx':
      case 'xls':
        return excel;
      case 'word':
      case 'docx':
      case 'doc':
        return word;
      case 'text':
        return text;

      case 'pptx':
      case 'ppt':
        return powerpoint;
      default:
        return defaultIcon;

    }

  }
  static filePreviewNotSupportedTypes = ['word', 'doc', 'docx', 'txt', 'xltx', 'xls', 'xlsx', 'xlsb', 'xlsm', 'csv', 'pptx', 'ppt', 'msg', 'eml'];
  static filePreviewSupportedTypes = ['pdf', 'jpeg', 'jpg', 'png', 'gif'];
  static imageTypes = ['jpeg', 'jpg', 'png'];
  static downloadFile = (fileId: any, FileName?: any) => { 
    let downloadUrl = getDownloadFileUrl(fileId);
    let fileType = CommonUtils.isNotEmpty(FileName) ? FileName.split('.').pop() : '' ;
    if (CommonUtils.isEmpty(FileName)) {
      window.open(downloadUrl);
    } else if (FileName.includes(".") &&  CommonUtils.isNotEmpty(fileType) && CommonUtils.filePreviewNotSupportedTypes.includes(fileType.toLowerCase())) {
      window.open(downloadUrl);
    } else {
      fetch(downloadUrl, {
        method: 'GET',
        headers: {
          'content-type': 'application/pdf',
          'accept': 'application/pdf, text/plain, */* ',
          'access-control-allow-origin': '*'
        }
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${FileName}`,
          );
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link?.parentNode?.removeChild(link);
        })
        .catch((err) => {
          console.log(err.message());
        });
    }
  }
 
 
  static handlePreviewOrDownloadFileNew = (kagamiFileModel: any, popupModel: any, fileString: any) => {
    let fileId:any;
    let fileName:any;
    let fileType:any;
    // const handleOnOkClick = () => {
    //   popupModel.state.setOpen(false);
    // }
    if(fileString.includes('.')){
      fileId = fileString.split(':')[0];
      fileName = fileString.split(':')[1];
      fileType = fileString.split('.').pop();
    }else{
      fileId = fileString;
    }
    let getControlValue =
      kagamiFileModel !== undefined && kagamiFileModel.controlDisplayName !== undefined
        ? kagamiFileModel.controlDisplayName
        : 'Confirmation ';
    const handleFileDownload = () => {
      return CommonUtils.downloadFile(fileId, fileName);
    }
    if (CommonUtils.isNotEmpty(fileType) && CommonUtils.filePreviewSupportedTypes.includes(fileType.toLowerCase())) {
      CommonUtils.previewFileInGrid(kagamiFileModel.controlDisplayName, fileId, popupModel, fileName, fileType)
    } 
    else if(CommonUtils.isNotEmpty(fileType) && CommonUtils.filePreviewNotSupportedTypes.includes(fileType.toLowerCase())) {
    handleFileDownload()
      buildtoastNotification('success',`File Downloaded`, 10)
      // popupModel.title = getControlValue;
      // popupModel.content = 'This is not a standard document type that can be viewed on this page, Check in Downloads List?';
      // popupModel.isModalDialogTemplate = false;
      // popupModel.modalWidth = 'sm';
      // popupModel.handleOnOk = handleOnOkClick
      // popupModel.state.setOpen(true);
  }
    else if(CommonUtils.isNotEmpty(fileId)){
      CommonUtils.previewFileInGrid(kagamiFileModel.controlDisplayName, fileId, popupModel, fileName, fileType)
    } 
  }

  static previewFileNew = (kagamiFileModel: any, docId: any, popupModel: any, fileName:string) => {
    let getControlValue =
      kagamiFileModel !== undefined && kagamiFileModel.controlDisplayName !== undefined
        ? kagamiFileModel.controlDisplayName
        : 'Attachments';
    // if(docId === '') {
    // docId = kagamiFileModel.kagamiFileModel.controlValue.split(':')[0]
    // // docId = kagamiFileModel.controlValue.split(':')[0]
    // }
    const kagamiDMSModel: KagamiDMSModel = new KagamiDMSModel();
    kagamiDMSModel.dmsResponseUrl = getDmsViewUrl(docId);
    popupModel.title = buildDownloadButton(getControlValue, docId, fileName);;
    popupModel.modalWidth = 'lg';
    popupModel.content = buildDms(kagamiDMSModel);
    popupModel.isdragable = false;
    popupModel.isModalDialogTemplate = true;
    popupModel.state.setOpen(true);

  };




  static handlePreviewOrDownloadFileInGrid = (controlDisplayName: any, popupModel: any, fileString: any) => {
    let fileId:any;
    let fileName:any;
    let fileType:any;
    // const handleOnOkClick = () => {
    //   popupModel.state.setOpen(false);
    // }
    if(fileString.includes('.')){
      fileId = fileString.split(':')[0];
      fileName = fileString.split(':')[1];
      fileType = fileString.split('.').pop();
    }else{
      fileId = fileString;
    }
    let getControlValue =
      CommonUtils.isNotEmpty(controlDisplayName)
        ? controlDisplayName 
        : 'Confirmation ';
    const handleFileDownload = () => {
      return CommonUtils.downloadFile(fileId, fileName);
    }
    if (CommonUtils.isNotEmpty(fileType) && CommonUtils.filePreviewSupportedTypes.includes(fileType.toLowerCase())) {
      CommonUtils.previewFileInGrid(controlDisplayName, fileId, popupModel, fileName, fileType)
    } 
    else if(CommonUtils.isNotEmpty(fileType) && CommonUtils.filePreviewNotSupportedTypes.includes(fileType.toLowerCase())) {
    handleFileDownload()
      buildtoastNotification('success',`File Downloaded`, 10)
      // popupModel.title = getControlValue;
      // popupModel.content = 'This is not a standard document type that can be viewed on this page, Check in Downloads List?';
      // popupModel.isModalDialogTemplate = false;
      // popupModel.modalWidth = 'sm';
      // popupModel.handleOnOk = handleOnOkClick
      // popupModel.state.setOpen(true);
  }
    else if(CommonUtils.isNotEmpty(fileId)){
      CommonUtils.previewFileInGrid(controlDisplayName, fileId, popupModel, fileName, fileType)
    } 
}
  static previewFileInGrid = (controlDisplayName: any, docId: any, popupModel: any, fileName: string, fileType?: any) => {
    if(fileType !== 'zip') {
    let getControlValue =
      CommonUtils.isNotEmpty(controlDisplayName)
        ? controlDisplayName 
        : 'Attachments';

    const handleClose = () => {
      popupModel.isModalDialogTemplate = false;

    }
     
    const kagamiDMSModel: KagamiDMSModel = new KagamiDMSModel();
    kagamiDMSModel.dmsResponseUrl = getDmsViewUrl(docId);
    kagamiDMSModel.modalPopUp = true;
    popupModel.title =  buildDownloadButton(getControlValue, docId, fileName);
    popupModel.modalWidth = 'lg';
    popupModel.content = buildDms(kagamiDMSModel);
    popupModel.isModalDialogTemplate = true;
    popupModel.handleOnClose = handleClose;
    popupModel.isdragable = false;
    popupModel.state.setOpen(true);
  } else {
      CommonUtils.downloadFile(docId, fileName);
  }
}

  //used  for Heirarchy view
  static getRandomColor() {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

}
