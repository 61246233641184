import { createState } from "@hookstate/core";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  KagamiDMSModel,
  KagamiDMSProps,
  useKagamiDMS,
} from "../model/kagamiDMSModel";
import { KagamiDMSServices } from "../service/kagamiDMSServices";
import { kagamiDMSStyle } from "../style/kagamiDMS";
import CloseIcon from "@mui/icons-material/Close";
import { KagamiProcessRequestModel } from "../../../model/KagamiProcessRequestModel";
import {
  loadProcessContent,
  onStartCall,
} from "../../../controller/KagamiProcessController";
import { showServerError } from "../../form/controller/KagamiFormController";
import { getProcessName } from "../../../../../../navigator/builder/Menu/controller/KagamiMenuController";

const useStyles = makeStyles(kagamiDMSStyle);

export const buildKagamiDms = (kagamiDms: KagamiDMSModel) => {
  return <KagamiDms key={Math.random()} kagamiDms={kagamiDms} />;
};

export const KagamiDms = (props: KagamiDMSProps) => {
  const classes = useStyles();
  const kagamiDmsState = useKagamiDMS(createState(props.kagamiDms));
  props.kagamiDms.state = kagamiDmsState;

  React.useEffect(() => {
    KagamiDMSServices.setPdfUrl(props.kagamiDms);
  }, []);

  const handleBackButton = () => {
    const processRequestModel = new KagamiProcessRequestModel(
      getProcessName(),
      loadProcessContent,
      showServerError
    );
    processRequestModel.processName = getProcessName();
    onStartCall(processRequestModel);
  };

  if (!kagamiDmsState.getPdfUrlLoaded()) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress style={{ margin: "0 auto" }} />
      </Box>
    );
  } else {
    return (
      <>
        {props.kagamiDms.isRespSuccess ? (
          <div style={{ position: "relative" }}>
            {props.kagamiDms.modalPopUp === false? 
            <Tooltip
            title="Close" arrow >
            <CloseIcon className={classes.closeIcon} 
              onClick={handleBackButton}/>
            </Tooltip>
             : ""}
            <iframe
              src={props.kagamiDms.pdfUrl}
              className={classes.iframe}
            ></iframe>
          </div>
        ) : (
          <Typography> File type not supported </Typography>
        )}
      </>
    );
  }
};
