import { State, useState } from '@hookstate/core';
import { KagamiTriggerModel } from '../../Triggers/builder/trigger/model/KagamiTriggerModel';

export class KagamiDMSModel {
  dmsResponseUrl: string;
  pdfUrl: string;
  isPdfUrlLoaded: boolean;
  state: any;
  presentations: any;
  kagamiTriggerModel: KagamiTriggerModel | null;
  isRespSuccess: boolean;
  modalPopUp: boolean;

  constructor() {
    this.dmsResponseUrl = '';
    this.pdfUrl = '';
    this.isPdfUrlLoaded = false;
    this.kagamiTriggerModel = null;
    this.isRespSuccess = true;
    this.modalPopUp = false;
  }
}

export interface KagamiDMSProps {
  kagamiDms: KagamiDMSModel;
}

export const wrapKagamiDMSModelState = (state: State<KagamiDMSModel>) => {
  const kagamiDmlState = state;
  return {
    getPdfUrlLoaded() {
      return kagamiDmlState.isPdfUrlLoaded.get();
    },
    setPdfUrlLoaded(value: boolean) {
      kagamiDmlState.isPdfUrlLoaded.set(value);
    }
  };
};

export const useKagamiDMS = (state: State<KagamiDMSModel>) => {
  return wrapKagamiDMSModelState(useState(state));
};
