import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';

export const kagamiDMSStyle = (theme:Theme) => createStyles({
    iframe:{
       minHeight:'510px',
       maxHeight:'800px',
       height:'calc(100vh - 110px)',
       width: '100%',
    },
    closeIcon: {
        position: 'absolute',
        color: 'white',
        right: '8rem',
        top: '1.1rem',
        '&:hover' : {
            backgroundColor: "#4c4c4c",
            cursor: "pointer",
            borderRadius: '50%',
            opacity: '1.0',
        },
      },
})